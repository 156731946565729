<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:checked']);

const props = defineProps({
	checked: {
		type: [Array, Boolean],
		required: true,
	},
	value: {
		default: null,
	},
});

const proxyChecked = computed({
	get() {
		return props.checked;
	},

	set(val) {
		emit('update:checked', val);
	},
});
</script>

<template>
	<input
		class="rounded border-gray-700 bg-gray-900 text-indigo-600 shadow-sm focus:ring-indigo-600 focus:ring-offset-gray-800"
		type="checkbox"
		:value="value"
		v-model="proxyChecked"
	/>
</template>
